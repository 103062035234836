import FooterImg from "../images/footer-01-web.jpg"
import ContactLogo from "../images/logo-w-new.svg"
import QRCode from "../images/qrcode.jpg"
import { animated, useSpring, config } from "@react-spring/web"
import * as React from "react"
import { Container } from "./layout"
import Form from "./form"
import { useTextTranslateEffect } from "../hooks/useTextTranslateEffect"
import Logo from "../images/footer-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { css } from "@emotion/react"
import BubbleLine from "../images/bubble-line.png"
import BubbleForm from "../images/bubble-form.png"

function ContactButton() {
  return (
    <a
      href={"https://line.me/R/ti/p/%40lotusclinic168"}
      css={{
        display: "block",
        color: "#fff",
        textDecoration: "none",
        backgroundColor: "#562c36",
        padding: "5px 10px",
        width: "fit-content",
        margin: "0 auto",
        "&:hover": {
          color: "#fff",
          textDecoration: "none",
          backgroundColor: "#d9c0c6",
        },
      }}
    >
      line@線上客服
    </a>
  )
}

const formContactButtonStyle = css({
  display: "flex",
  justifyContent: "center",
  color: "#cb9f45",
  paddingTop: 15,
  paddingBottom: 15,
  letterSpacing: "1.5px",
  fontSize: 20,
  border: "1px solid #cb9f45",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#cb9f45",
    textDecoration: "none",
  },
})

function Footer() {
  const { observe, styles } = useTextTranslateEffect()
  return (
    <footer>
      <div css={{ backgroundColor: "#fbf8f3" }}>
        <Container>
          <div
            css={{
              display: "grid",
              gridAutoFlow: "row",
              rowGap: 15,
              marginBottom: 40,
            }}
          >
            <a
              href={"tel:+886800599666"}
              css={[formContactButtonStyle, { borderTopLeftRadius: 30 }]}
              target={"_blank"}
            >
              <div>
                <FontAwesomeIcon icon={faPhone} css={{ marginRight: 10 }} />
                <span>0800-599-666</span>
              </div>
            </a>
            <a
              href={"https://line.me/R/ti/p/%40lotusclinic168"}
              css={[formContactButtonStyle]}
              target={"_blank"}
            >
              Line聯繫
            </a>
            <div css={[formContactButtonStyle, { backgroundColor: "#fff" }]}>
              新北市板橋區中山路一段104號
            </div>
            <a
              href={"https://goo.gl/maps/YzicK1Jiidr9FppM9"}
              css={[formContactButtonStyle, { borderBottomRightRadius: 30 }]}
              target={"_blank"}
            >
              <div css={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  css={{ marginRight: 10 }}
                />
                導航 Google 地圖
              </div>
            </a>
          </div>
          <Form />
        </Container>
      </div>
      <div
        css={{
          position: "relative",
        }}
      >
        <img src={FooterImg} css={{ width: "100%", maxWidth: 1110 }} />
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: 15,
            alignItems: "center",
            "@media(min-width: 1440px)": {
              flexDirection: "row-reverse",
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            },
          }}
        >
          <img src={Logo} css={{ width: 60 }} />
          <p
            css={{
              writingMode: "vertical-rl",
              fontSize: 21,
              lineHeight: 1.8,
            }}
          >
            陪伴每個人自信優雅並美麗的老去，
            <br />
            以健康的心境擁抱生命的歷程。
            <br />
            這是一個再次與自己愛戀的旅途，
            <br />
            一路相伴，是我們的溫柔堅持。
            <br />
            「美無極菡生」為妳而生，
            <br />
            擁抱生命的歡愉，賦予美麗新意義。
          </p>
        </div>
      </div>
      <div css={{ backgroundColor: "#d9c0c6" }} ref={observe}>
        <Container
          css={{
            "@media(min-width: 768px)": {
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <div
            css={{
              backgroundColor: "#875c6d",
              padding: "30px 15px",
              textAlign: "center",
              color: "#fff",
              "@media(min-width: 768px)": {
                textAlign: "left",
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "center",
                columnGap: 15,
              },
            }}
          >
            <div css={{ textAlign: "center" }}>
              <img
                src={ContactLogo}
                css={{
                  width: 200,
                  marginBottom: 30,
                  "@media(min-width: 768px)": {
                    marginBottom: 15,
                    width: 155,
                  },
                }}
                style={styles}
              />
              <img
                src={QRCode}
                css={{
                  width: 134,
                  display: "none",
                  margin: " 0 auto",
                  "@media(min-width: 768px)": {
                    display: "block",
                  },
                }}
              />
              <div
                css={{
                  display: "none",
                  marginTop: 15,
                  "@media(min-width: 768px)": {
                    display: "block",
                  },
                }}
              >
                <ContactButton />
              </div>
            </div>
            <div>
              <div
                css={{
                  overflow: "hidden",
                  margin: "0 auto",
                  marginBottom: 15,
                  width: "fit-content",
                  "@media(min-width: 768px)": {
                    margin: "0 0 15px 0",
                  },
                }}
              >
                <animated.h5
                  css={{
                    fontSize: 20,
                    padding: "0 20px",
                    fontWeight: "normal",
                  }}
                  style={styles}
                >
                  聯絡我們
                </animated.h5>
                <div
                  css={{ width: "100%", height: 2, backgroundColor: "#fff" }}
                />
              </div>
              <p
                css={{
                  display: "grid",
                  gridTemplateColumns: "max-content max-content",
                  columnGap: 5,
                  justifyContent: "center",
                  color: "#fff",
                  "@media(min-width: 768px)": {
                    justifyContent: "start",
                  },
                }}
              >
                <span>週一至週五 </span>
                <span>09:00~20:00</span>
                <span css={{ justifySelf: "start" }}>週六 </span>
                <span>10:00~19:00</span>
              </p>
              <p
                css={{
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "center",
                  color: "#fff",
                  "@media(min-width: 768px)": {
                    justifyContent: "start",
                  },
                }}
              >
                服務專線 (02)2959-5000
                <br />
                客服專線 0800-599-666
              </p>
              <address>新北市板橋區中山路一段104號</address>
              <div
                css={{
                  "@media(min-width: 768px)": {
                    display: "none",
                  },
                }}
              >
                <ContactButton />
              </div>
            </div>
          </div>
          <div>
            <iframe
              style={{ border: 0, minHeight: 350 }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.6991590884522!2d121.46045331500584!3d25.010336483983437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a81dd51f2159%3A0xc03192838e097b29!2zMjIw5paw5YyX5biC5p2_5qmL5Y2A5Lit5bGx6Lev5LiA5q61MTA06Jmf!5e0!3m2!1szh-TW!2stw!4v1611839854097!5m2!1szh-TW!2stw"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen="allowfullscreen"
            />
          </div>
        </Container>
      </div>
      <div
        css={{
          display: "none",
          width: "100%",
          color: "#fff",
          backgroundColor: "#562c36",
          minHeight: 55,
          justifyContent: "center",
          alignItems: "center",
          "@media(min-width: 768px)": {
            display: "flex",
          },
        }}
      >
        菡生美無極診所 © {new Date().getFullYear()} , All Rights Reserved
      </div>
    </footer>
  )
}

export default Footer
